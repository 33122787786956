function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import markerIO from "@fireworx/components/marker-io";
import GlobalStyles from "@fireworx/styles/global";
import type { AppProps } from "next/app";
import localFont from "next/font/local";
import Script from "next/script";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const corsa = localFont({
  src: [{
    path: "../fonts/CorsaGrotesk-XThin.ttf",
    weight: "200",
    style: "normal"
  }, {
    path: "../fonts/CorsaGrotesk-Regular.ttf",
    weight: "400",
    style: "normal"
  }, {
    path: "../fonts/CorsaGrotesk-Medium.ttf",
    weight: "500",
    style: "normal"
  }, {
    path: "../fonts/CorsaGrotesk-Bold.ttf",
    weight: "700",
    style: "normal"
  }, {
    path: "../fonts/CorsaGrotesk-XBold.ttf",
    weight: "800",
    style: "normal"
  }, {
    path: "../fonts/CorsaGrotesk-Black.ttf",
    weight: "900",
    style: "normal"
  }],
  variable: "--font-corsa"
});

/** Initialize Marker.io */
markerIO();
var _ref = process.env.NODE_ENV === "production" ? {
  name: "uivd70",
  styles: "font-family:var(--font-corsa), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\""
} : {
  name: "1ui9dor-App",
  styles: "font-family:var(--font-corsa), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\";label:App;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function App({
  Component,
  pageProps
}: AppProps) {
  return _jsxs("main", {
    className: corsa.variable,
    css: _ref,
    children: [_jsx(GlobalStyles, {}), _jsx(Component, {
      ...pageProps
    }), _jsx(Script, {
      src: `https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`,
      strategy: "afterInteractive"
    }), _jsx(Script, {
      id: "google-analytics",
      strategy: "afterInteractive",
      children: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}');
        `
    })]
  });
}